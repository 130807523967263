// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-idea-js": () => import("./../../../src/pages/idea.js" /* webpackChunkName: "component---src-pages-idea-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markets-js": () => import("./../../../src/pages/markets.js" /* webpackChunkName: "component---src-pages-markets-js" */),
  "component---src-pages-orchards-js": () => import("./../../../src/pages/orchards.js" /* webpackChunkName: "component---src-pages-orchards-js" */),
  "component---src-pages-products-apple-juice-300-js": () => import("./../../../src/pages/products/apple-juice-300.js" /* webpackChunkName: "component---src-pages-products-apple-juice-300-js" */),
  "component---src-pages-products-apple-juice-750-js": () => import("./../../../src/pages/products/apple-juice-750.js" /* webpackChunkName: "component---src-pages-products-apple-juice-750-js" */),
  "component---src-pages-products-floral-honey-240-js": () => import("./../../../src/pages/products/floral-honey-240.js" /* webpackChunkName: "component---src-pages-products-floral-honey-240-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */)
}

