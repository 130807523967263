module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 375px)","sm":"(max-width: 767px)","md":"(max-width: 1023px)","l":"(max-width: 1279px)","xl":"(max-width: 1439px)","portrait":"(orientation: portrait)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["ru","en"],"defaultLanguage":"ru","siteUrl":"https://yasnaya.ru/","i18nextOptions":{"fallbackLng":"ru","interpolation":{"escapeValue":false},"nsSeparator":false,"transEmptyNodeValue":"","transSupportBasicHtmlNodes":true,"transKeepBasicHtmlNodesFor":["br","strong","i"]},"pages":[]},
    }]
